function FetchData(type, id) {
    GetData(`${process.env.REACT_APP_API_URL}/${type}${id ? '/' + id : ''}`)
        .then(response => {
            return response
        })
        .catch(error => {
            console.error(error);
        })
}

async function GetData(type) {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/${type}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include',
        redirect: 'manual',
        referrerPolicy: 'no-referrer',
    });
    if (response.status === 200) {
        return response.json();
    }
    else if(window.location.pathname !== "/") {
        return {status: response.status}
    }
}

async function PostData(type, data = {}) {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/${type}`, {
        method: 'POST',
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include',
        redirect: 'manual',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify(data)
    })

    if (response.status === 401){
        if(window.location.pathname !== "/" && window.location.pathname !== "/cms/"){
            window.location.href = "/"
        }
        throw await response.json()
    }
    return response.json();
}

async function PostFormData(type, data = {}) {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/${type}`, {
        method: 'POST',
        cache: 'no-cache',
        credentials: 'include',
        redirect: 'manual',
        referrerPolicy: 'no-referrer',
        body: data
    })

    if (response.status === 401){
        if(window.location.pathname !== "/" && window.location.pathname !== "/cms/"){
            window.location.href = "/"
        }
    }
    return response.json();
}

export {
    PostData,
    PostFormData,
    GetData,
    FetchData as default
}